<template>
  <div class="login-view pt-4">
    <div class="login-header">
      <img
        width="300"
        src="@/assets/rectoplus_logo_white_font.png"
      >
    </div>
    <div class="form-login-container">
      <span class="login-title">{{ $t('views.login.title') }}</span>
      <span class="login-info">{{ $t('views.login.info') }}</span>
      <login-form @submited="doLogin" />
      <router-link
        :to="{ name: 'recover-password' }"
      >
        {{ $t('views.login.recover_password') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoginForm from '@/components/forms/login/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'hasSession',
      'hasBusiness',
    ]),
  },
  watch: {
    hasSession(value) {
      const { redirect } = this.$route.query;
      if (value && redirect) this.$router.push(redirect);
      if (value && !redirect) this.$router.push({ name: 'home' });
    },
  },
  mounted() {
    if (this.hasSession && this.hasBusiness) this.$router.push('/home');
  },
  methods: {
    ...mapActions([
      'initLoading',
    ]),
    doLogin(params) {
      if (this.hasSession) return this.$router.push('/home');
      this.initLoading();
      return this.$store.dispatch('login', params);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .login-header {
    color: $title_color_primary;
    font-size: 40px;
    font-weight: bold;
  }
  .login-title {
    color: $title_color_primary;
    font-size: 26px;
    font-weight: bold;
  }
  .login-info {
    color: $subtitle_color_primary;
    font-size: 16px;
  }
  .form-login-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
</style>
