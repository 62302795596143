<template>
  <v-form
    class="login-form"
    @keyup.native.enter="executeLogin"
  >
    <validation-provider
      v-slot="{errors}"
      name="email"
      class="text-field"
      rules="required|email"
    >
      <label
        for="emailInput"
        class="label-login"
      >
        {{ $t('components.login_form.labels.email') }}
      </label>
      <v-text-field
        id="emailInput"
        v-model="email"
        class="text-field"
        :label="$t('components.login_form.placeholders.email')"
        type="email"
        background-color="field_register"
        flat
        solo
        :error-messages="errors"
      />
    </validation-provider>

    <validation-provider
      v-slot="{errors}"
      name="senha"
      class="text-field"
      rules="required|min:6"
    >
      <label
        for="passwordInput"
        class="label-login"
      >
        {{ $t('components.login_form.labels.password') }}
      </label>
      <v-text-field
        id="passwordInput"
        v-model="password"
        class="text-field"
        background-color="field_register"
        :label="$t('components.login_form.placeholders.password')"
        type="password"
        flat
        solo
        :error-messages="errors"
      />
    </validation-provider>
    <rectoplus-button
      id="loginButton"
      class="login-btn"
      :text="$t('components.login_form.login_btn')"
      elevation="0"
      @click="executeLogin"
    />
    <a
      id="startRegister"
      class="btn-link"
      @click="redirectToRegister"
    >
      {{ $t('components.login_form.register') }}
    </a>
  </v-form>
</template>
<script>
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'LoginForm',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    executeLogin() {
      const userData = { email: this.email, password: this.password };
      this.$emit('submited', userData);
    },
    redirectToRegister() {
      this.$router.push('/register');
    },
    // Method to change de show/no show password
    // changePassword() {
    //   this.showPassword = !this.showPassword;
    // },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  margin: 32px 16px 16px;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-field {
    width: 100%;
    max-width: 416px;
    border-radius: 8px;
  }
  .login-btn {
    width: 100%;
    max-width: 416px;
    margin: 20px 0px;
    font-size: 20px;
    height: 60px;
  }
  .label-login {
    color: $color_label_login;
    font-size: 16px;
    font-weight: 600;
  }
  .btn-link {
    font-weight: bold;
    font-size: 20px;
    padding: 16px;
  }
}
</style>
